@import '../../../../scss/theme-bootstrap';

.header-offers-banner-formatter {
  display: flex;
  height: $offerbar-mobile-height;
  position: relative;
  width: 100%;
  @include breakpoint($landscape-up) {
    height: $offerbar-height;
  }
  .gnav-offers-hidden & {
    display: none;
    height: 0;
    min-height: 0;
  }
  &__carousel {
    display: flex;
    align-items: center;
    margin: 0 auto;
    position: relative;
    width: 100%;
    padding-#{$ldirection}: $container-pad-small;
    padding-#{$rdirection}: $container-pad-small;
    @include breakpoint($landscape-up) {
      padding-#{$ldirection}: $container-pad-landscape;
      padding-#{$rdirection}: $container-pad-landscape;
    }
    &-item {
      display: none;
      &.item-active {
        display: block;
        width: 100%;
        @include breakpoint($landscape-up) {
          width: auto;
        }
      }
      .fade & {
        animation-name: fade;
        animation-duration: 1.5s;
      }
    }
    &-btn {
      a {
        background: 0 0;
        border: none;
        height: 15px;
        position: absolute;
        width: 15px;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .icon {
        color: $color-black;
        fill: $color-black;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 0;
      }
      .icon--caret--left {
        #{$ldirection}: 5px;
        text-align: $ldirection;
        @include breakpoint($landscape-up) {
          #{$ldirection}: 5%;
        }
      }
      .icon--caret--right {
        #{$rdirection}: 5px;
        text-align: $rdirection;
        @include breakpoint($landscape-up) {
          #{$rdirection}: 5%;
        }
      }
    }
  }
  &__arrow-prev {
    #{$ldirection}: 20px;
    @include breakpoint($landscape-up) {
      #{$ldirection}: 5%;
    }
  }
  &__arrow-next {
    #{$rdirection}: 20px;
    @include breakpoint($landscape-up) {
      #{$rdirection}: 5%;
    }
  }
  a {
    color: $color-white;
  }
}

@keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
